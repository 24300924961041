<template>
  <div>
    <div class="h-fit w-100">
      <div
        class="mt-4 fs-3 fw-bold underline text-center"
      >
        {{ $tcust('ForgotPassword.title') }}
      </div>

      <form
        id="registerForm"
        class="p-3 m-auto"
        onsubmit="return false"
      >
        <!-- Email input -->
        <MDBInput
          id="UserName"
          v-model="UserName"
          type="email"
          autocomplete="username"
          required
          :label="$tcust('common.form.email')"
          wrapper-class="mb-4"
          class="text-center my-3"
        />
        <!-- Submit button -->
        <div class="d-flex justify-content-center">
          <MDBBtn
            color="primary"
            block
            class="w-75 fw-bold"
            type="submit"
            @click="submit"
          >
            <span>{{ $tcust("common.form.validate") }}</span>
          </MDBBtn>
        </div>
      </form>    
    </div>
  </div>
</template>

<script setup>
import {
  MDBInput,
  MDBBtn
} from "mdb-vue-ui-kit";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

const UserName = ref("");

const submit = async () => {
  await store
    .dispatch("resetPassword", { UserName: UserName.value, ClientSiteUrl: "https://client.eecheck.app/#/lang" })
    .then(() => {
      router.push({
        name: "ForgotPasswordComplete",
      });
    })
};
</script>

<style lang="scss" scoped>
form {
  min-width: 280px;
  width: 350px;
  max-width: 90vw;
}
</style>
